import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import * as Images from "../images";
import { isMobileOnly } from 'react-device-detect';
import CheckBoxComponent from "../form-action-utils/CheckBoxComponent";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const SensorChartComponent = (props) => {
  const { context, isViewChart, isResultPage, isPreview, handleViewSensors, isPreviewStatusPage } = props;
  const { t } = useTranslation();

  const { chartSetting: {
      dataChart,
      optionsChart,
      statusLegendList,
      isNormalize,
      maxX, minX, stepSizeX,
      maxY, minY, stepSizeY,
      isConvertHour
    },
    isMultiDate,
    changeLegendStatus,
    changeNormalizeStatus,
    settingStepSizeY,
    settingMinY,
    settingMaxY,
    settingStepSizeX,
    settingMinX,
    settingMaxX,
    changeOptionViewXAxis,
    isSettingLegendMB,
    updateIsSettingLegendMB
  } = useContext(context);

  const [axis, setAxis] = useState("");
  const [isSettingScale, setIsSettingScale] = useState(false);

  const chartRef = useRef(null);

  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  useEffect(() => {
    if(chartRef.current) {
      updateLegend(chartRef.current);
    }
  }, [props])
  
  const updateLegend = (chart) => {
    statusLegendList.map((item, index) => {
      chart.setDatasetVisibility(index, !item);
    });
    chart.update();
    forceUpdate();
  }

  const handleShowSettingScale = (axis) => {
    setIsSettingScale(true);
    setAxis(axis);
  }

  const closeSetting = () => {
    setIsSettingScale(false);
    setAxis("");
  }

  const renderActionsChart = (chartInfo) => {
    if(chartInfo && !isPreview) {
      return (
        <div className='legend ct-scroll'>
          <ul class="legend-list">
            {chartInfo.legend.legendItems.map((item, index) => (
              <li class={"legend-item" + (item.hidden ? " disable-line" : "")} onClick={isMobileOnly ? undefined : () => changeLegendStatus(index)}>
                <span style={{ background: item.strokeStyle}}></span>
                <p>{item.text}</p>
              </li>
            ))}
          </ul>
          {!isMobileOnly &&
            <div className='normalize'>
              <CheckBoxComponent
                onChange={() => {changeNormalizeStatus(!isNormalize)}}
                value={isNormalize}
                title="Normalize"
              />
            </div>
          }
        </div>
      )
    }
  }

  const getType = () => {
    if(isConvertHour) {
      if(isMultiDate) return "datetime-local"
      return "time"
    }

    return "number"
  }

  return (
    <>
      <div 
        className='sensor-chart' 
        style={{ display: isViewChart ? "block" : "none" }} 
        onDoubleClick={!isMobileOnly && isPreview ? handleViewSensors : undefined}
      >
        <div className='chart ct-scroll'>
          <div className="canvas">
            {isResultPage && !isPreviewStatusPage &&
              <button 
                className='st-scale-y' 
                style={{
                  top: 0,
                  width: chartRef.current ? chartRef.current.scales.y.width + "px" : 0,
                  height: chartRef.current ? `calc(100% - ${chartRef.current.scales.x.height}px)` : 0
                }}
                onClick={() => handleShowSettingScale("Y")}
              />
            }
            <Line
              ref={chartRef}
              options={{...optionsChart}}
              data={dataChart}
            />
            {isResultPage && !isPreviewStatusPage &&
              <button
                className='st-scale-y' 
                style={{
                  bottom: 0,
                  width: "100%",
                  height: chartRef.current ? chartRef.current.scales.x.height + "px" : 0,
                }}
                onClick={() => handleShowSettingScale("X")}
              />
            }
          </div>

          {isMobileOnly ?
            <>
              <div id='legend-container' className='legend'>
                {renderActionsChart(chartRef.current)}
              </div>
              {!isResultPage &&
                <div className='normalize'>
                  <CheckBoxComponent
                    onChange={() => {changeNormalizeStatus(!isNormalize)}}
                    value={isNormalize}
                    title="Normalize"
                  />
                </div>
              }
            </>
            :
            renderActionsChart(chartRef.current)
          }
        </div>
      </div>

      {isMobileOnly &&
        <Modal
          centered
          show={isSettingLegendMB}
          onHide={() => updateIsSettingLegendMB(false)}
          onClick={e => e.stopPropagation()}
        >
          <Modal.Body className='modal-setting-dataset chart'>
            <button className='close' onClick={() => updateIsSettingLegendMB(false)}>
              <Images.IconCloseSensors />
            </button>

            <div className="legend">
              {chartRef.current &&
                <div className='legend-list'>
                  {chartRef.current.legend.legendItems.map((item, index) => (
                    <li class="legend-item">
                      <label>
                        <div className="form-checkbox">
                          <input 
                            type="checkbox" 
                            onChange={() => changeLegendStatus(index)} 
                            checked={!item.hidden}
                          />
                          <Images.IconCheck />
                        </div>
                        <span style={{background: item.strokeStyle}}></span>
                        <p>{item.text}</p>
                      </label>
                    </li>
                  ))}
                </div>
              }
            </div>
            <button className='ok' onClick={() => updateIsSettingLegendMB(false)}>
              OK
            </button>

          </Modal.Body>
        </Modal>
      }

      {!isPreview && 
        <Modal centered className="setting-axis" backdropClassName='setting-axis' show={isViewChart && isSettingScale} onHide={closeSetting}>
          <Modal.Header style={{display: 'flex', justifyContent: 'space-between'}}>
            {
              {
                X: "Time axis setting",
                Y: "Y axis setting"
              }[axis]
            }
            <button style={{border: 0, background: "transparent", marginLeft: "auto"}} onClick={closeSetting}><Images.IconClose color="#5F55C4" width={20} /></button>
          </Modal.Header>

          <Modal.Body>
            {
              {
                X: (
                  <>
                    <div className='edit-user'>
                      <div className="item">
                        <label>Min</label>
                        <div>
                          <input type={getType()} step={isConvertHour ? 2 : 1} value={minX} onChange={(e) => settingMinX(e)} />
                        </div>
                      </div>
                      <div className="item">
                        <label>Max</label>
                        <div>
                          <input type={getType()} step={isConvertHour ? 2 : 1} value={maxX} onChange={(e) => settingMaxX(e)} />
                        </div>
                      </div>
                      <div className="item">
                        <label>Step size</label>
                        <div>
                          <input type="number" value={stepSizeX} onChange={(e) => settingStepSizeX(e.target.value)} />
                        </div>
                      </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px', padding: '6px 0'}}>
                      <label className="form-radio">
                        <input type="radio" name="x-option" checked={!isConvertHour} defaultChecked onChange={() => changeOptionViewXAxis(false)} />
                        <span className='checkmark'></span>
                        Elapsed Time
                      </label>
                      <label className="form-radio">
                        <input type="radio" name="x-option" checked={isConvertHour} onChange={() => changeOptionViewXAxis(true)} />
                        <span className='checkmark'></span>
                        Hour and Minute
                      </label>
                    </div>
                  </>
                ),
                Y: (
                  <div className='edit-user'>
                    <div className="item">
                      <label>Min</label>
                      <div>
                        <input type="number" value={minY} onChange={(e) => settingMinY(e.target.value)} />
                      </div>
                    </div>
                    <div className="item">
                      <label>Max</label>
                      <div>
                        <input type="number" value={maxY} onChange={(e) => settingMaxY(e.target.value)} />
                      </div>
                    </div>
                    <div className="item">
                      <label>Step size</label>
                      <div>
                        <input disabled={isNormalize} type="number" value={stepSizeY} onChange={(e) => settingStepSizeY(e.target.value)} />
                      </div>
                    </div>
                  </div>
                )
              }[axis]
            }
          </Modal.Body>
        </Modal>
      }
    </>
  );
};

export default SensorChartComponent;