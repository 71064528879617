import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Images from "../../components/images";
import { Reorder, useDragControls, useMotionValue, animate } from "framer-motion";

const inactiveShadow = "0px 0px 0px rgba(0,0,0,0.8)";

const FlightMarker = ({marker, position, chargingStation, addMoreMarker, updateMarker, deleteMarker, errorElement, errorMessage}) => {
  const { t, i18n } = useTranslation();
  const developerMode = Boolean(localStorage.getItem("developerMode") === "true");

  const handleInputChange = (event) => {
    event.preventDefault();
    let { name, value } = event.target;
    if (name === "id") {
      value = parseInt(value);
    }
    if (name === 'angle') {
      if (!value) {
        value = '';
      }
      else {
        value = parseInt(value);
      }
    }
    let tempMarker = { ...marker, [name]: value };
    updateMarker(tempMarker, position);
    if (name === "action_type") {
      let tempMarker = { ...marker, action: { ...marker.action, "type": value } };
      updateMarker(tempMarker, position);
    }
    if (name === "action_hold_t") {
      let tempMarker = { ...marker, action: { ...marker.action, "hold_t": value } };
      updateMarker(tempMarker, position);
    }
    if (name === "action_rotate") {
      let tempMarker = { ...marker, action: { ...marker.action, "rotate": value } };
      updateMarker(tempMarker, position);
    }
  };
  
  const y = useMotionValue(0);
  const dragControls = useDragControls();
  const boxShadow = useMotionValue(inactiveShadow);

  useEffect(() => {
    let isActive = false;
    y.onChange((latest) => {
      const wasActive = isActive;
      if (latest !== 0) {
        isActive = true;
        if (isActive !== wasActive) {
          animate(boxShadow, "5px 5px 10px rgba(0,0,0,0.3)");
        }
      } else {
        isActive = false;
        if (isActive !== wasActive) {
          animate(boxShadow, inactiveShadow);
        }
      }
    });
  }, [y, boxShadow]);

  return (
    <Reorder.Item value={marker} className='marker_item' dragListener={false} dragControls={dragControls} style={{ y, boxShadow , background: '#fff', userSelect: 'none'}}>
      <div className="marker_item-number">
        <Images.IconScan />
        <input
          type="number"
          min={1}
          max={100}
          step={1}
          name="id"
          onChange={handleInputChange}
          value={marker.id}
        />
        <span onPointerDown={(event) => dragControls.start(event)}>
          <Images.IconChangeMarker />
        </span>
        <div className="mt-1 error-message"> {errorElement === position + ".id" ? errorMessage : ""}</div>
      </div>
      <div className="marker_item-content">
        <table className="main_form">
          <tr>
            <td>
              <div className="form-select">
                <span>{t('plan.marker.drone.angle')}</span>
                <label>
                  <input
                    className="dps-input-primary with-50percent"
                    type="number"
                    step={1}
                    min={-180}
                    max={180}
                    name="angle"
                    onChange={handleInputChange}
                    value={marker.angle}
                  />
                  {errorElement === position + ".angle" && <span className="error-message d-inline">{errorMessage}</span>}
                </label>
              </div>
            </td>
            <td>
              <div className="form-select">
                <span>{t('plan.marker.drone.height')}</span>
                <label>
                  <input
                    className="dps-input-primary with-50percent"
                    type="number"
                    min={0}
                    name="height"
                    onChange={handleInputChange}
                    value={marker.height}
                  />
                  {errorElement === position + ".height" && <span className="error-message d-inline">{errorMessage}</span>}
                </label>
              </div>
            </td>
            <td>
              <div className="form-select">
                <span>{t('plan.marker.drone.wall_distance')}</span>
                <label>
                  <input
                    className="dps-input-primary with-50percent"
                    type="number"
                    min={0}
                    name="wall_distance"
                    onChange={handleInputChange}
                    value={marker.wall_distance}
                  />
                  {errorElement === position + ".wall_distance" && <span className="error-message d-inline">{errorMessage}</span>}
                </label>
              </div>
            </td>
            <td>
              <div className="form-select">
                <span>{t('plan.marker.drone.landing_port')}</span>
                <label>
                  <select
                    className="dps-input-primary"
                    value={marker.isStation}
                    name="isStation"
                    onChange={handleInputChange}
                  >
                    <option value="" disabled selected>
                      {t("common.please_select")}
                    </option>
                    <option value="false">{t("common.no")}</option>
                    {chargingStation.map((item, index) => {
                      return (
                        <option key={index} value={item.stationId}>
                          {item.stationId}
                        </option>
                      );
                    })}
                    <option value="else">{t("common.others")}</option>
                  </select>
                  <Images.IconSelect color="currentColor" />
                  {errorElement === position + ".station" && <span className="error-message d-inline">{errorMessage}</span>}
                </label>
              </div>
            </td>
            <td>
              <div className="form-select">
                <span>{t('plan.marker.drone.direction')}</span>
                <label>
                  <select
                    className="dps-input-primary"
                    value={marker.direction}
                    name="direction"
                    onChange={handleInputChange}
                  >
                    <option value="" disabled selected>
                      {t("common.please_select")}
                    </option>
                    <option value="right">{t("common.right")}</option>
                    <option value="left">{t("common.left")}</option>
                    <option value="turn">{t("common.turn")}</option>
                    <option value="up">{t("common.up")}</option>
                    <option value="down">{t("common.down")}</option>
                    <option value="landed">{t("common.landing")}</option>
                  </select>
                  <Images.IconSelect color="currentColor" />
                  {errorElement === position + ".direction" && <span className="error-message d-inline">{errorMessage}</span>}
                </label>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div className="form-select">
                <span>{t('plan.marker.drone.marker_distance')}</span>
                <label>
                  <input
                    className="dps-input-primary with-50percent"
                    type="number"
                    min={0}
                    name="marker_distance"
                    onChange={handleInputChange}
                    value={marker.marker_distance}
                  />
                  {errorElement === position + ".marker_distance" && <span className="error-message d-inline">{errorMessage}</span>}
                </label>
              </div>
            </td>
            <td>
              {developerMode &&
                <div className="form-select">
                    <span>{t('plan.marker.drone.mTemp')}</span>
                    <label>
                      <input
                        disabled={!developerMode}
                        className="dps-input-primary"
                        type="text"
                        name="mTemp1"
                        onChange={handleInputChange}
                        value={marker.mTemp1 || ""}
                      />
                      {errorElement === position + ".mTemp" && <span className="error-message d-inline">{errorMessage}</span>}
                    </label>
                </div>
              }
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div> 
      <div className="marker_item-action">
        <button className="btn-remove_marker" onClick={deleteMarker}>
          <Images.Icons name="IconRemove" color="currentColor" />
        </button>
        <button className="btn-add_marker" onClick={() => { addMoreMarker(marker, position + 1) }}>
          <Images.Icons name="IconAdd" color="transparent" colorText='#473DAF' />
        </button>
      </div>
    </Reorder.Item>
  );
};

export default FlightMarker;
