import React, { useEffect, useRef, useState, memo } from 'react';
import axios from 'axios';
import ExifReader from 'exifreader';
import { NotificationManager } from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert';
import { t } from 'i18next';
import { isMobileOnly } from 'react-device-detect';
import { Modal } from 'react-bootstrap';

import { getHeader } from "../../services/api.service"
import { timeSmallConverter } from '../../utils/utils';
import * as Constants from "../../utils/constants"
import * as Images from "../images";
import CheckBoxComponent from "../form-action-utils/CheckBoxComponent";
import SelectComponent from "../form-action-utils/SelectComponent";

const IconPrev = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="30" viewBox="0 0 17 30" fill="none">
    <path d="M16.1421 0.819336L1.99995 14.9615L16.1421 29.1036" stroke="#5F55C4" stroke-width="2"/>
  </svg>
)

const IconNext = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="30" viewBox="0 0 17 30" fill="none">
    <path d="M0.85791 29.1035L15 14.9614L0.85791 0.819245" stroke="#5F55C4" stroke-width="2"/>
  </svg>
)

const ImagesView = (props) => {
  const { 
    device: { 
      deviceStatus, 
      inspection: { 
        id: inspectionId, 
        planNo
      } 
    } 
  } = props;

  const [optionMarkerInfo, setOptionMarkerInfo] = useState(null);
  const [imageList, setImageList] = useState(null);
  const [imageListView, setImageListView] = useState(imageList);
  const [viewStatus, setViewStatus] = useState('all'); // all, marker, detail
  const [valueSelect, setValueSelect] = useState(null);
  const [imagesByMarker, setImageByMarker] = useState(null);
  const [currentIndexImagesPreview, setCurrentIndexImagesPreview] = useState(0);
  const [isLoadingCurrentImg, setIsLoadingCurrentImg] = useState(false);
  const [imageSelecteds, setImageSelecteds] = useState([]);
  const [isDisable, setIsDisable] = useState(false);
  const [isViewFormSort, setIsViewFormSort] = useState(false);
  const [isFilterSetting, setIsFilterSetting] = useState(false);
  const [sortStatus, setSortStatus] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [isValidate, setIsValidate] = useState(false);

  //Mobile
  const [isViewDetailMB, setIsViewDetailMB] = useState(false);
  const [dataViewDetailMB, setDataViewDetailMB] = useState(null);
  const [currentIndexImageViewMB, setCurrentIndexImageViewMB] = useState(0);

  // View detail img
  const [dataImgDetail, setDataImgDetail] = useState(null);
  const [currentIndexImgDetail, setCurrentIndexImgDetail] = useState(null);
  const [meataData, setMeataData] = useState(null);
  const [isLoadingMetaData, setIsLoadingMetaData] = useState(false);
  const [src, setSrc] = useState(null);

  const inputFilterRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, [props])

  useEffect(() => {
    if (imageList) {
      setImageListView(imageList);
      setOptionMarkerInfo(convertOptionMarkerInfo(imageList));
    }
  }, [imageList])

  useEffect(() => {
    if (imageListView) {
      let newImageListView = [...imageListView];
      switch (sortStatus) {
        case "name-desc": 
          newImageListView.sort((a, b) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          }); 
          break;
        case "name-asc": 
          newImageListView.sort((a, b) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            if (nameA < nameB) return 1;
            if (nameA > nameB) return -1;
            return 0;
          });  
          break;
        case "date-newest": newImageListView.sort((a, b) => b.utc - a.utc); break;
        case "date-oldest": newImageListView.sort((a, b) => a.utc - b.utc); break;
      
        default: newImageListView = [...imageList]; break;
      }
  
      setImageListView(newImageListView);
    }
  }, [sortStatus])

  useEffect(() => {
    if(filterValue) {
      const imageListViewFilter = imageList.filter(item => {
        const fileNameSplit = item.name.split(".");
        fileNameSplit.pop();
        return fileNameSplit.join(".").endsWith(filterValue);
      });
      setImageListView(imageListViewFilter);
    }
  }, [filterValue])

  useEffect(() => {
    if(inputFilterRef.current && isFilterSetting) {
      inputFilterRef.current.focus();
    }
  }, [isFilterSetting])

  useEffect(() => {
    setIsLoadingCurrentImg(true);
  }, [currentIndexImagesPreview])

  const fetchData = () => {
    const promiseGetImageList = axios.get(Constants.LIST_IMAGE_BY_INSPECTION_ID + "/" + inspectionId);

    Promise.allSettled([
      promiseGetImageList
    ]).then(responseArr => {
      if (responseArr[0].status === "fulfilled" && responseArr[0].value !== "false") {
        setImageList(responseArr[0].value.data);
      }
    })
  }

  const refetchImagesList = () => {
    fetchData();
  }

  const convertOptionMarkerInfo = (imageList) => {
    const deviceStatusNotMediaAndLeft = [...deviceStatus].filter(item => item.type !== "media" && item.status !== "marker_left");
    const optionMarkerInfo = [];

    deviceStatusNotMediaAndLeft.forEach((item, index, arr) => {
      const nextItem = arr[index + 1];
      const markerInfo = {};

      if(nextItem) {
        if (nextItem.status === "landed") {
          const markerIdStart = item.status === "takeoff" ? "Takeoff" : item.content.marker_id;
          const utcStart = +item.content.utc;
          const utcEnd = null;
          const dataImage = [...imageList].filter(item => item.utc >= utcStart);

          markerInfo['value'] = markerIdStart + "-Landed" + Math.random() * Date.now();
          markerInfo['title'] = "Marker " + markerIdStart + "-Landed";
          markerInfo['markerStart'] = markerIdStart;
          markerInfo['utcStart'] = utcStart;
          markerInfo['utcEnd'] = utcEnd;
          markerInfo['dataImage'] = dataImage;

          optionMarkerInfo.push(markerInfo);
        } else {
          const markerIdStart = item.status === "takeoff" ? "Takeoff" : item.content.marker_id;
          const utcStart = +item.content.utc;
          const utcEnd = +nextItem.content.utc;
          const dataImage = [...imageList].filter(item => item.utc >= utcStart && item.utc < utcEnd);
          
          markerInfo['value'] = markerIdStart + "-" + nextItem.content.marker_id + "-" + Math.random() * Date.now();
          markerInfo['title'] = "Marker " + markerIdStart + "-" + nextItem.content.marker_id;
          markerInfo['markerStart'] = markerIdStart;
          markerInfo['utcStart'] = utcStart;
          markerInfo['utcEnd'] = utcEnd;
          markerInfo['dataImage'] = dataImage;
  
          optionMarkerInfo.push(markerInfo);
        }
      }
    })

    return optionMarkerInfo;
  }

  const handleChangeMarkerImage = (e) => {
    const { value } = e.target;
    
    setValueSelect(value);
    setCurrentIndexImagesPreview(0);

    if (value === "all") {
      setViewStatus("all");
      setImageByMarker(null);
    } else {
      const currentMarkerData = optionMarkerInfo.find(item => item.value === value);
      setViewStatus("marker");
      setImageByMarker(currentMarkerData);
    }
  }

  const timeConverter = (UNIX_timestamp, t) => {
    if (!UNIX_timestamp) {
      return "";
    }
    const a = new Date(UNIX_timestamp * 1000);
    const year = a.getFullYear();
    const month = ('0' + (a.getMonth() + 1)).substr(-2);
    const date = ('0' + a.getDate()).substr(-2);
    const hour = ('0' + a.getHours()).substr(-2);
    const min = ('0' + a.getMinutes()).substr(-2);
    const sec = ('0' + a.getSeconds()).substr(-2);
  
    return year + "." + month + "." + date + ' ' + hour + ':' + min + ':' + sec;
  }

  const getCurrentTimeString = () => {
    const a = new Date();
    const year = a.getFullYear();
    const month = ('0' + (a.getMonth() + 1)).substr(-2);
    const date = ('0' + a.getDate()).substr(-2);
    const hour = ('0' + a.getHours()).substr(-2);
    const min = ('0' + a.getMinutes()).substr(-2);
    const sec = ('0' + a.getSeconds()).substr(-2);

    return year + month + date + hour + min + sec;
  }

  const handleToggleImage = (data) => {
    const newImageSelecteds = [...imageSelecteds];

    if(newImageSelecteds.find(it => it.id === data.id)) {
      const i = newImageSelecteds.findIndex(item => item.id === data.id);
      newImageSelecteds.splice(i, 1);
    } else {
      newImageSelecteds.push(data);
    }

    setImageSelecteds(newImageSelecteds);
  }

  const handleSelectAllImages = () => {
    if(imageSelecteds.length < imageListView.length) {
      setImageSelecteds([...imageListView]);
    }
    
    if (imageSelecteds.length === imageListView.length) {
      setImageSelecteds([]);
    }
  }

  const handleDownloadImages = (e, data) => {
    e.preventDefault();
    e.stopPropagation();

    setIsViewFormSort(false);
    setIsFilterSetting(false);

    const downloadImageFile = async (img) => {
      setIsDisable(true);
      try {
        const url = Constants.DOWNLOAD_IMAGE + "?" + new URLSearchParams({ keyName: img.raw });
        const response = await fetch(url, {...getHeader()});
        const imageBlob = await response.blob();
  
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(imageBlob);
        link.download = img.name || img.raw;
        document.body.appendChild(link);
        link.click();
    
        document.body.removeChild(link);
        setIsDisable(false);
      } catch (error) {
        setIsDisable(false);
      }
    }

    const downloadImageZip = async (imgs) => {
      setIsDisable(true);
      try {
        const fileName = getCurrentTimeString() + "-" + planNo + ".zip";
        
        const params = new URLSearchParams();
        imgs.forEach((item) => {
          params.append('keyName', item.raw);
        })

        const url = Constants.DOWNLOAD_IMAGE + "?" + params.toString();

        const response = await fetch(url, {...getHeader()});
        const imageBlob = await response.blob();

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(imageBlob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
    
        document.body.removeChild(link);
        setIsDisable(false);
      } catch (error) {
        setIsDisable(false);
      }
    };

    if(data) {
      downloadImageFile(data);
    } else {
      if(imageSelecteds.length > 1) {
        downloadImageZip(imageSelecteds);
      } else {
        downloadImageFile(imageSelecteds[0]);
      }
    }
  }

  const handleRemoveImage = (e, id) => {
    e.preventDefault();
    e.stopPropagation();

    setIsViewFormSort(false);
    setIsFilterSetting(false);

    confirmAlert({
      title: t("dialog_delete.message_text"),
      message: "",
      buttons: [
        {
          label: t("common.ok"),
          onClick: () => {
            axios.delete(Constants.REMOVE_IMAGE, {
              ...getHeader(),
              data: {
                fileId: id ? [id] : imageSelecteds.map(item => item.id),
                inspectionId
              }
            }).then(res=>{
              setImageSelecteds([...imageSelecteds].filter(item => ! res.data.some(it => it.id === item.id)))
              refetchImagesList();

              NotificationManager.success(
                t("dialog_delete.confirm_success"),
                "",
                2000
              );
            }).catch(err => {
              NotificationManager.error(
                t("dialog_delete.confirm_error"),
                "",
                2000
              );
            })
          },
        },
        {
          label: t("dialog_delete.option_no"),
        },
      ],
    });
  }

  const handleSort = (e) => {
    setIsFilterSetting(false);
    if(e.target.tagName === "BUTTON") {
      setIsViewFormSort(!isViewFormSort);
    }
  }

  const changeSortStatus = (e) => {
    e.stopPropagation();
    setSortStatus(e.target.value);
  }

  const handleShowFormFilter = (e) => {
    setIsViewFormSort(false);
    if(e.target.tagName === "BUTTON" && e.target.className !== "btn-ok") {
      setIsFilterSetting(!isFilterSetting);
    }
  }

  const handleFilter = (e) => {
    e.preventDefault();

    if(!inputFilterRef.current.value) {
      setIsValidate(false);
      setIsFilterSetting(false);
      setFilterValue("");
      setImageListView(imageList);
    } else if(inputFilterRef.current.value.length === 2) {
      setIsValidate(false);
      setIsFilterSetting(false);
      setFilterValue("_" + inputFilterRef.current.value);
    } else {
      setIsValidate(true);
    }
  }

  const handlePreImg = (maxLength) => {
    if(currentIndexImagesPreview <= 0) {
      setCurrentIndexImagesPreview(maxLength - 1);
    } else {
      setCurrentIndexImagesPreview(currentIndexImagesPreview - 1);
    }
  }

  const handleNextImg = (maxLength) => {
    if(currentIndexImagesPreview >= maxLength - 1) {
      setCurrentIndexImagesPreview(0);
    } else {
      setCurrentIndexImagesPreview(currentIndexImagesPreview + 1);
    }
  }

  const onLoadImage = () => {
    setIsLoadingCurrentImg(false);
  }

  const handleViewDetail = (e, data, index) => {
    e.preventDefault();
    e.stopPropagation();

    setIsViewFormSort(false);
    setIsFilterSetting(false);

    setDataImgDetail(data);
    setCurrentIndexImgDetail(index);
    setViewStatus('detail');

    getSrcImgPreview(data.raw);
  }

  const handleViewDetailMB = (e, data) => {
    e.preventDefault();
    e.stopPropagation();

    for (let index = 0; index < optionMarkerInfo.length; index++) {
      const element = optionMarkerInfo[index];

      for (let i = 0; i < element.dataImage.length; i++) {
        const imageByMarker = element.dataImage[i];
        if (imageByMarker.id === data.id) {
          setDataViewDetailMB(element);
          setCurrentIndexImageViewMB(i)
          setIsViewDetailMB(true);

          break;
        }
      }
    }
  }

  const handleCloseViewDetaulMB = () => {
    setIsViewDetailMB(false);
    setDataViewDetailMB(null);
    setCurrentIndexImageViewMB(0);
  }

  const getSrcImgPreview = async (raw) => {
    setSrc(null);
    setMeataData(null);
    setIsLoadingCurrentImg(true);
    setIsLoadingMetaData(true);

    const url = Constants.DOWNLOAD_IMAGE + "?" + new URLSearchParams({ keyName: raw });
    const response = await fetch(url, {...getHeader()});
    const imageBlob = await response.blob();

    setIsLoadingCurrentImg(false);
    setSrc(window.URL.createObjectURL(imageBlob));
  }

  const handleCloseViewDetail = () => {
    setSrc(null);
    setDataImgDetail(null);
    setCurrentIndexImgDetail(null);
    setViewStatus('all');
  }

  const onLoadImageDetail = async (e) => {
    function flattenObject(obj) {
      return Object.keys(obj).reduce((acc, key) => {
        return { ...acc, [key]: obj[key].description };
      }, {});
    }

    try {
      const response = await fetch(e.target.src);
      const buffer = await response.arrayBuffer();

      const tags = ExifReader.load(buffer);
      
      setMeataData(flattenObject(tags));
      setIsLoadingMetaData(false);
    } catch (error) {
      setIsLoadingMetaData(false);
      setMeataData({"Err reading metadata": ''});
    }
  }

  const handlePrevImgDetail = () => {
    if(currentIndexImgDetail > 0) {
      setCurrentIndexImgDetail(currentIndexImgDetail - 1);
      setDataImgDetail(imageListView[currentIndexImgDetail - 1]);
      getSrcImgPreview(imageListView[currentIndexImgDetail - 1].raw);
    } else {
      setCurrentIndexImgDetail(imageListView.length - 1);
      setDataImgDetail(imageListView[imageListView.length - 1]);
      getSrcImgPreview(imageListView[imageListView.length - 1].raw);
    }
  }

  const handleNextImgDetail = () => {
    if(currentIndexImgDetail < imageListView.length - 1) {
      setCurrentIndexImgDetail(currentIndexImgDetail + 1);
      setDataImgDetail(imageListView[currentIndexImgDetail + 1]);
      getSrcImgPreview(imageListView[currentIndexImgDetail + 1].raw);
    } else {
      setCurrentIndexImgDetail(0);
      setDataImgDetail(imageListView[0]);
      getSrcImgPreview(imageListView[0].raw);
    }
  }

  const renderAllImages = () => {
    if(optionMarkerInfo) {
      const dataSelect = [
        {
          value: "all",
          title: "All Markers"
        },
        ...optionMarkerInfo
      ]

      if (isMobileOnly) {
        return (
          <>
            <div className='image-header'>
              <div className="actions">
                <span className="img-selected" style={!imageSelecteds.length ? {display: "none"} : undefined}>{imageSelecteds.length + t("result.selected")}</span>
                {/* <button onClick={handleSelectAllImages} className={'btn-select-all' + (imageSelecteds.length === imageListView.length ? " active" : "")}>
                  <img src={require("../../assets/images/slectall.png")} alt="" />
                </button> */}
                <button onClick={handleDownloadImages} disabled={!imageSelecteds.length || isDisable}><Images.IconDownloadImages color="#5F55C4" /></button>
                <button className="btn-delete-image" onClick={(e) => handleRemoveImage(e)} disabled={!imageSelecteds.length}><Images.IconDeleteImages color="#5F55C4" /></button>
                <button onClick={handleSort} className='btn-sort-image'>
                  <Images.IconSortImages color="#5F55C4" />
                  <div className="form-sort" style={!isViewFormSort ? {display: 'none'} : undefined}>
                    <label>
                      <input type="radio" name="sort-img" value="name-desc" onChange={changeSortStatus} />
                      <span><Images.IconCheck color="#fff" /></span>
                      <p>Name: A &#8594; Z</p>
                    </label>
                    <label>
                      <input type="radio" name="sort-img" value="name-asc" onChange={changeSortStatus} />
                      <span><Images.IconCheck color="#fff" /></span>
                      <p>Name: Z &#8594; A</p>
                    </label>
                    <label>
                      <input type="radio" name="sort-img" value="date-newest" onChange={changeSortStatus} />
                      <span><Images.IconCheck color="#fff" /></span>
                      <p>Date: Newest</p>
                    </label>
                    <label>
                      <input type="radio" name="sort-img" value="date-oldest" onChange={changeSortStatus} />
                      <span><Images.IconCheck color="#fff" /></span>
                      <p>Date: Oldest</p>
                    </label>
                  </div>
                </button>
                <button onClick={handleShowFormFilter} className='btn-filter-image'>
                  <Images.IconFilter color="#5F55C4" />
                  <form className="form-filter" style={!isFilterSetting ? {display: 'none'} : undefined}>
                    <div className="mask">
                      <input type="text" maxLength={2} ref={inputFilterRef} onChange={() => setIsValidate(false)} />
                      {isValidate && <span>format: '_xx'</span>}
                    </div>
                    <button className='btn-ok' onClick={handleFilter}>Ok</button>
                  </form>
                </button>
              </div>
            </div>

            {imageListView.length ?
              <div className='image-body ct-scroll'>
                {imageListView.map((item, index) => (
                  <div className='image-item' key={item.id}>
                    <CheckBoxComponent
                      onChange={() => handleToggleImage(item)}
                      value={!!imageSelecteds.find(it => it.id === item.id)}
                    />

                    <img src={Constants.getResourceUrl() + item.raw} onClick={(e) => handleViewDetailMB(e, item)} />

                    <div className='image-item-info'>
                      <p className='image-name'>{item.name}</p>
                      <span className='image-time'>{timeConverter(item.utc)}</span>
                    </div>
                  </div>
                ))}
              </div>
              :
              <div className='no-img'>
                Not image uploaded
              </div>
            }

            {dataViewDetailMB &&
              <Modal
                fullscreen
                show={isViewDetailMB}
                contentClassName="images-view-detail-mb"
              >
                <Modal.Body className='detail-img-mb'>
                  <div className='detail-img-mb-header'>
                    <p>
                      {dataViewDetailMB.markerStart !== "Takeoff" ? `Mrker ${dataViewDetailMB.markerStart}` : "Takeoff"} | {timeSmallConverter(dataViewDetailMB.utcStart)}
                    </p>
                    <button onClick={handleCloseViewDetaulMB}>
                      <Images.IconCloseSensors />
                    </button>
                  </div>

                  <img src={Constants.getResourceUrl() + dataViewDetailMB.dataImage[currentIndexImageViewMB].raw} />

                  <p className='img-name'>
                    {dataViewDetailMB.dataImage[currentIndexImageViewMB].name}
                  </p>

                  <div className='detail-img-mb-list'>
                    {dataViewDetailMB.dataImage.map((item, index) => (
                      <div 
                        className={'img-view-item' + (currentIndexImageViewMB === index ? " active" : "")}
                        onClick={() => setCurrentIndexImageViewMB(index)}
                      >
                        <img src={Constants.getResourceUrl() + item.raw} />
                        <div className='img-view-item-info'>
                          <span>{item.name}</span>
                          <span>{timeConverter(+item.utc)}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </Modal.Body>
              </Modal>
            }
          </>
        )
      }

      return (
        <>
          <div className='image-header'>
            <SelectComponent
              name="select-marker-image"
              data={dataSelect}
              value={valueSelect}
              onChange={handleChangeMarkerImage}
            />

            <div className="actions">
              <span className="img-selected" style={!imageSelecteds.length ? {display: "none"} : undefined}>{imageSelecteds.length + t("result.selected")}</span>
              {/* <button onClick={handleSelectAllImages} className={'btn-select-all' + (imageSelecteds.length === imageListView.length ? " active" : "")}>
                <img src={require("../../assets/images/slectall.png")} alt="" />
              </button> */}
              <button onClick={handleDownloadImages} disabled={!imageSelecteds.length || isDisable}><Images.IconDownloadImages color="#5F55C4" /></button>
              <button className="btn-delete-image" onClick={(e) => handleRemoveImage(e)} disabled={!imageSelecteds.length}><Images.IconDeleteImages color="#5F55C4" /></button>
              <button onClick={handleSort} className='btn-sort-image'>
                <Images.IconSortImages color="#5F55C4" />
                <div className="form-sort" style={!isViewFormSort ? {display: 'none'} : undefined}>
                  <label>
                    <input type="radio" name="sort-img" value="name-desc" onChange={changeSortStatus} />
                    <span><Images.IconCheck color="#fff" /></span>
                    <p>Name: A &#8594; Z</p>
                  </label>
                  <label>
                    <input type="radio" name="sort-img" value="name-asc" onChange={changeSortStatus} />
                    <span><Images.IconCheck color="#fff" /></span>
                    <p>Name: Z &#8594; A</p>
                  </label>
                  <label>
                    <input type="radio" name="sort-img" value="date-newest" onChange={changeSortStatus} />
                    <span><Images.IconCheck color="#fff" /></span>
                    <p>Date: Newest</p>
                  </label>
                  <label>
                    <input type="radio" name="sort-img" value="date-oldest" onChange={changeSortStatus} />
                    <span><Images.IconCheck color="#fff" /></span>
                    <p>Date: Oldest</p>
                  </label>
                </div>
              </button>
              <button onClick={handleShowFormFilter} className='btn-filter-image'>
                <Images.IconFilter color="#5F55C4" />
                <form className="form-filter" style={!isFilterSetting ? {display: 'none'} : undefined}>
                  <div className="mask">
                    <input type="text" maxLength={2} ref={inputFilterRef} onChange={() => setIsValidate(false)} />
                    {isValidate && <span>format: '_xx'</span>}
                  </div>
                  <button className='btn-ok' onClick={handleFilter}>Ok</button>
                </form>
              </button>
            </div>
          </div>
          {imageListView.length ?
            <div className='image-body ct-scroll'>
              {imageListView.map((item, index) => (
                <div 
                  className={'image-item' + (imageSelecteds.find(it => it.id === item.id) ? " selected" : "")}
                  key={item.id}
                  onClick={() => handleToggleImage(item)}
                >
                  <img src={Constants.getResourceUrl() + item.raw} onClick={(e) => handleViewDetail(e, item, index)} />

                  <div className='image-item-info'>
                    <p className='image-name'>{item.name}</p>
                    <span className='image-time'>{timeConverter(item.utc)}</span>
                  </div>
                </div>
              ))}
            </div>
            :
            <div className='no-img'>
              Not image uploaded
            </div>
          }
        </>
      )
    }
  }

  const renderImagesByMarker = () => {
    if(optionMarkerInfo && imagesByMarker) {
      const dataSelect = [
        {
          value: "all",
          title: "All Markers"
        },
        ...optionMarkerInfo
      ]

      const { dataImage } = imagesByMarker;

      const currentDataImage = dataImage[currentIndexImagesPreview];

      return (
        <>
          <div className='image-header'>
            <SelectComponent
              name="select-marker-image"
              data={dataSelect}
              value={valueSelect}
              onChange={handleChangeMarkerImage}
            />

            {dataImage.length ? 
              <span className='image-preview-info'>
                {timeSmallConverter(currentDataImage.utc)} | {currentDataImage.name}
              </span>
              : null
            }

            <div className="actions">
              <button 
                className='btn-show-list' 
                onClick={() => {
                  setViewStatus('all')
                  setValueSelect('all')
                }}
              >
                <Images.IconList />
              </button>
            </div>
          </div>

          {dataImage.length ?
            <div className='image-body ct-scroll'>
              <div className='img-carousel'>
                <div className='img-selected'>
                  <button 
                    className='btn-pre'
                    onClick={() => handlePreImg(dataImage.length)}
                  >
                    <IconPrev />
                  </button>

                  <div className='img-wrap'>
                    {isLoadingCurrentImg &&
                      <div className="loading">
                        <img
                          alt="loading"
                          src={require("../../assets/images/loading.gif")}
                        />
                      </div>
                    }

                    <img src={Constants.getResourceUrl() + currentDataImage.raw} onLoad={onLoadImage} />
                  </div>
                  
                  <button 
                    className='btn-next'
                    onClick={() => handleNextImg(dataImage.length)}
                  >
                    <IconNext />
                  </button>
                </div>

                <div className='img-dots'>
                  {dataImage.map((item, index) => (
                    <div 
                      className={'dot-item' + (currentIndexImagesPreview === index ? " selected" : "")} 
                      key={item.id}
                      onClick={() => setCurrentIndexImagesPreview(index)}
                    >
                      <img src={Constants.getResourceUrl() + item.raw} />
                      <span>{timeSmallConverter(item.utc)}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            : 
            <div className='no-img'>
              Not image uploaded
            </div>
          }
        </>
      )
    }
  }

  const renderImageDetail = () => {
    if(dataImgDetail) {
      return (
        <div className='image-body ct-scroll' style={{ flex: 1 }}>
          <div className='img-detail-wrap'>
            <button className='btn-close-detail-img' onClick={handleCloseViewDetail}><Images.IconList /></button>

            <div className='img-view'>
              <button onClick={handlePrevImgDetail}><IconPrev /></button>

              <div className='img-wrap'>
                {isLoadingCurrentImg &&
                  <div className="loading">
                    <img
                      alt="loading"
                      src={require("../../assets/images/loading.gif")}
                    />
                  </div>
                }
                <img src={src} onLoad={onLoadImageDetail} />
              </div>

              <button onClick={handleNextImgDetail}><IconNext /></button>
            </div>

            <div className='img-metadata ct-scroll'>
              {isLoadingMetaData &&
                <div className="loading">
                  <img
                    alt="loading"
                    src={require("../../assets/images/loading.gif")}
                  />
                </div>
              }
              {meataData && renderMetaData()}
            </div>
          </div>
        </div>
      )
    }
  }

  const renderMetaData = () => {
    return (
      <div className="metadata-table">
        {Object.keys(meataData).map(item => (
          <div className="row-cs">
            <div className="col-cs">
              <span>{item}</span>
            </div>
            <div className="col-cs">
              <span>{meataData[item]}</span>
            </div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className='images-wrapper'>
      {
        {
          all: renderAllImages(),
          marker: renderImagesByMarker(),
          detail: renderImageDetail(),
        }[viewStatus]
      }
    </div>
  )
};

export default memo(ImagesView);