import React, { Suspense } from "react";
import PropTypes from "prop-types";
import * as Images from "./images";
import { confirmAlert } from "react-confirm-alert"; // Import
import { withTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { STAND_ALONE, SYSTEM_INFO_URL, getBackendUrl } from "../utils/constants";
import { Modal } from "react-bootstrap";
import { doGet } from "../services/api.service";
import AuthService from "../services/auth.service";
import { FE_VER } from "../utils/constants";
import { withRouter } from "react-router-dom";
import preval from 'preval.macro'

class MenuAdmin extends React.PureComponent {
  constructor(props) {
    super(props);
    const { t, i18n } = props;
    this.state = {
      showModal: false,
      systemInfo: null,
      menus: {
        avatar: {
          image: Images.Logo,
          username: "menu-admin.manager",
        },
        body: [
          {
            icon: "IconUser",
            title: "user.title",
            active: "/user",
            url: "/user",
          },
          {
            icon: "IconSetting",
            title: "menu-admin.setting",
            active: "/setting/",
            url: "/setting/profile",
          },
        ],
        footer: [
          {
            icon: "IconLogout",
            title: "menu-admin.logout",
            active: "/logout/",
            url: "/logout",
          },
        ],
      },
      appMinorVersion: 0
    };
    this.handleLogout.bind(this);
    this.renderQRCode.bind(this);
    this.onClickAvatar.bind(this);
    this.handleClose.bind(this);
  }

  componentDidMount() {
    const dateTimeStamp = preval`module.exports = new Date().toLocaleString();`
    const now = new Date(dateTimeStamp);
    const year = now.getFullYear().toString().slice(-2)
    const month = (now.getMonth() + 1).toString().padStart(2, '0')
    const day = now.getDate().toString().padStart(2, '0')
    const hous = now.getHours().toString().padStart(2, '0')
    const minute = now.getMinutes().toString().padStart(2, '0')

    const appMinorVersion = year + month + day + hous + minute;

    this.setState({
      appMinorVersion
    })
  }

  onClickAvatar() {
    if (STAND_ALONE) {
      doGet(SYSTEM_INFO_URL, null, (res) => {
        this.setState({
          showModal: true,
          systemInfo: res.data,
        });
      });
    }
  }

  handleClose() {
    if (STAND_ALONE) {
      this.setState({ showModal: false });
    }
  }

  renderQRCode(t) {
    if (STAND_ALONE) {
      if (this.state.systemInfo) {
        const { ipAddress, hostName } = this.state.systemInfo;
        // const location = window.location;
        // const accessPoint =
        //   location.protocol +
        //   "//" +
        //   ipAddress +
        //   (location.port ? ":" + location.port : "");

        const tempAccessPoint = getBackendUrl().split(':')
        tempAccessPoint.pop();
        const accessPoint = tempAccessPoint.join(':');
        return (
          <Modal
            centered
            show={this.state.showModal}
            onHide={() => this.handleClose()}>
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                <Suspense fallback={<div>Loading...</div>}>
                  <div style={{ textAlign: "center" }}>
                    <div style={{ fontSize: "36px" }}>{accessPoint}</div>
                    <QRCode value={accessPoint} />
                    <h5>Hostname:{hostName}</h5>
                  </div>
                </Suspense>
              }
            </Modal.Body>
          </Modal>
        );
      } else {
        return (
          <Modal
            centered
            show={this.state.showModal}
            onHide={() => this.handleClose()}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div>{t("setting.can_not_get_ip_address")}</div>
            </Modal.Body>
          </Modal>
        );
      }
    }
  }

  handleLogout(e, t) {
    e.preventDefault();
    confirmAlert({
      title: t("menu-admin.do_you_want_to_logout"),
      message: "",
      buttons: [
        {
          label: t("common.ok"),
          onClick: () => {
            this.props.history.push("/logout");
          },
        },
        {
          label: t("common.back"),
        },
      ],
    });
  }

  renderItemMenu(context, data, t) {
    return data.map((item, index) => {
      if (item.url === "/logout") {
        return (
          <div key={index}>
            <a
              href="#"
              onClick={(e) => this.handleLogout(e, t)}
              className={
                this.props.currentRouter.includes(item.url)
                  ? "active menu-item"
                  : "menu-item"
              }>
              <Images.Icons name={item.icon} color="#222" />
              <span className="icon-text">{t(item.title)}</span>
            </a>
            <div
              className={
                this.props.currentRouter === item.url
                  ? "choose-btn"
                  : "display-none"
              }>
            </div>
          </div>
        );
      } else {
        return (
          <div key={index} style={{display: 'flex', justifyContent: 'space-between'}}>
            <a
              href={item.url}
              className={
                this.props.currentRouter.includes(item.active)
                  ? "active menu-item"
                  : "menu-item"
              }>
              <Images.Icons name={item.icon} color="currentColor" />
              <span className="icon-text">{t(item.title)}</span>
            </a>
            <div
              className={
                this.props.currentRouter.includes(item.active)
                  ? "choose-btn"
                  : "display-none"
              }>
            </div>
          </div>
        );
      }
    });
  }

  render() {
    const { t, i18n } = this.props;
    const currentUser = AuthService.getCurrentUser();
    return (
      <section className="tab-menu f-column">
        {this.renderQRCode(t)}
        <div className="mheader">
          <div className="avatar f-row f-center">
            <img
              className="image"
              alt="spiral logo"
              src={this.state.menus.avatar.image}
              onClick={() => this.onClickAvatar()}
            />

            <span className="textAva">{t(this.state.menus.avatar.username)}</span>
          </div>
          {this.renderItemMenu(this, this.state.menus.body, t)}
        </div>

        <div className="mfooter">
          <div className="menu-item version">FE: {FE_VER + '.' + this.state.appMinorVersion}</div>
          <div className="menu-item version">
            BE: {currentUser ? currentUser.backendVersion : ""}
          </div>
          {this.renderItemMenu(this, this.state.menus.footer, t)}
        </div>
      </section>
    );
  }
}

export default withRouter(withTranslation()(MenuAdmin));

MenuAdmin.defaultProps = {
  currentRouter: "",
};

MenuAdmin.propTypes = {
  currentRouter: PropTypes.string,
};
